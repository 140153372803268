import styled from "styled-components"

const StyledHeader = styled.header`
  background: white;

  &.flexbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    margin: .5rem;
  }

`;

export default StyledHeader;