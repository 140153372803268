import React from "react";
import StyledFooter from "./Footer.styled";

const Footer = () => {

    return (
        <StyledFooter id="footer">
            <p className="text-white text-center">Copyright &copy; {new Date().getFullYear()}. All rights reserved.</p>
        </StyledFooter>
    )
}

export default Footer;