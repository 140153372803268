import styled from "styled-components";

const StyledFooter = styled.section`
  background: var(--kmeegaBlue);

  .text-center {
    text-align: center;
  }
  .text-white {
    color: #fff;
  }

`;

export default StyledFooter;