import React from "react"
import GlobalStyles from '../styles/GlobalStyles';
import ContainerStickyFooter from "./Common/ContainerStickyFooter.styled";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

const Layout = ({children}) => {

    return (
        <>
            <GlobalStyles/>
            <ContainerStickyFooter>
                <Header/>
                <main>
                    {children}
                </main>
                <Footer/>
            </ContainerStickyFooter>
        </>
    )
};

export default Layout;
