import styled from "styled-components";

const StyledPixel = styled.div.attrs(props => ({
    className: props.className,
    backgroundColor: props.backgroundColor,
    animationName: props.animationName,
}))`

  .flexbox-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .flexbox-container > div {
   line-height: 1.0;
  }
  
  .text-white {
    color: white;
  }
  .text-black {
    color: black;
  }
  .text-magenta {
    color: magenta;
  }
  .text-kmeegaBlue {
    color: var(kmeegaBlue);
  }
  .text-cyan {
    color: cyan;
  }
  .text-green {
    color: green;
  }
  .text-yellow {
    color: yellow;
  }
  .text-kmeegaOrange {
    color: var(kmeegaOrange);
  }
  .text-red {
    color: red;
  }

  /* background-color: $ {props.backgroundColor}; does not work! */
  background-color: ${props => props.backgroundColor};

 
  animation-name: ${props => props.animationName};
  animation-duration: 28s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;

  @keyframes redPixel {
    0%, 19.42%, 100% {
      transform: translate(0rem, 0rem) scale(1);
      z-index: 0;
    }
    13.42% {
      transform: translate(4.25rem, -4.25rem) scale(2);
      z-index: 1;
    }
  }

  @keyframes orangePixel {
    0% {
      transform: translate(0rem, 0rem) scale(1);
      z-index: 0;
    }
    13.42% {
      transform: translate(0rem, 0rem) scale(1);
    }
    26.84% {
      transform: translate(4.25rem, -4.25rem) scale(2);
      z-index: 1;
    }
    32.84% {
      transform: translate(0rem, 0rem) scale(1);
      z-index: 0;
    }
    100% {
      transform: translate(0rem, 0rem) scale(1);
      z-index: 0;
    }
  }

  @keyframes yellowPixel {
    0%, 26.84%, 46.26%, 100% {
      transform: translate(0rem, 0rem) scale(1);
      z-index: 0;
    }
    40.26% {
      transform: translate(1.25rem, -5.25rem) scale(2);
      z-index: 1;
    }
  }

  @keyframes greenPixel {
    0%, 40.26%, 59.68%, 100% {
      transform: translate(0rem, 0rem) scale(1);
    }
    53.68% {
      transform: translate(1.25rem, -4.25rem) scale(2);
    }
  }

  @keyframes cyanPixel {
    0%, 53.68%, 73.10%, 100% {
      transform: translate(0rem, 0rem) scale(1);
    }
    67.10% {
      transform: translate(-5.25rem, -4.25rem) scale(2);
    }
  }

  @keyframes bluePixel {
    0%, 67.10%, 100% {
      transform: translate(0rem, 0rem) scale(1);
    }
    80.52% {
      transform: translate(-4.25rem, -4.25rem) scale(2);
    }
  }

  @keyframes magentaPixel {
    0%, 100% {
      transform: translate(0rem, 0rem) scale(1);
      z-index: 0;
    }
    80.52% {
      transform: translate(0rem, 0rem) scale(1);
      z-index: 1;
    }
    93.94% {
      transform: translate(-4.25rem, 3.25rem) scale(2);
    }
    99% {
      z-index: 1;
    }
  }

`;

export default StyledPixel;