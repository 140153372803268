import React from "react";
import StyledPixel from "./Pixel.styled";

const Pixel = ({className, backgroundColor, animationName, children}) => {

    return (
        <StyledPixel className={className}
                     backgroundColor={backgroundColor}
                     animationName={animationName}>
            {children}
        </StyledPixel>
    );

}

export default Pixel;