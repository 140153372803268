import {createGlobalStyle} from 'styled-components'

const GlobalStyles = createGlobalStyle`

  :root {
    --font-san-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --kmeegaBlue: #243777;
    --kmeegaOrange: #ff7700;
    --max-width: 1320px;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }
  
  html {
    font-family: var(--font-san-serif);
  }

  body {
    margin: 0;
    padding: 0;
    font-family: var(--font-san-serif);
    font-size: calc(1rem + .25vw);
    overflow-x: hidden;
  }
 
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500; 
    line-height: 1.5;
  }

  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
  /*@media (min-width: 1200px) {
    h1 {
      font-size: 2.5rem;
    }
  }*/

  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
  @media (min-width: 1200px) {
    h2 {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  @media (min-width: 1200px) {
    h3 {
      font-size: 1.75rem;
    }
  }

  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
  @media (min-width: 1200px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

`

export default GlobalStyles;