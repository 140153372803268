import React from "react";
import StyledHeader from "./Header.styled";
import KmeegaLogo from "../../images/KmeegaLogo.svg";

const Header = () => {

    return (
        <StyledHeader id="header" className="flexbox-container">
            <img src={KmeegaLogo} height="70rem" className="logo" alt="Kmeega Logo"/>
        </StyledHeader>
    );
}

export default Header;