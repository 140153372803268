import styled from "styled-components"

const ContainerStickyFooter = styled.div`

  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
 
  main {
    background: white; 
  }
  
`

export default ContainerStickyFooter;