import React from "react"
import Layout from "../components/Layout";
import LogoGrid from "../components/LogoGrid/LogoGrid";

const IndexPage = () => {

  return (
      <Layout>
        <LogoGrid/>
      </Layout>
  )
}

export default IndexPage;
