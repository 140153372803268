import React from "react";
import StyledLogoGrid from "./LogoGrid.styled";
import Pixel from "./Pixel";
import {FaHandHolding} from "react-icons/all";

const LogoGrid = () => {

    return (
        <StyledLogoGrid>

            <div/>
            <div/>
            <div/>
            <div/>
            <Pixel className="ratio ratio-1x1"
                   backgroundColor="magenta"
                   animationName="magentaPixel">
                <div className="flexbox-container text-yellow">
                    <div>Measure data</div>
                    <div>to</div>
                    <div>define</div>
                    <div>predictability</div>
                </div>
            </Pixel>

            <div/>
            <div/>
            <div/>
            <Pixel className="ratio ratio-1x1"
                   backgroundColor="blue"
                   animationName="bluePixel">
                <div className="flexbox-container text-white">
                    <div>Embrace</div>
                    <div>creativity with</div>
                    <div>video</div>
                    <div>&</div>
                    <div>animation</div>
                </div>
            </Pixel>
            <div/>

            <div/>
            <div/>
            <Pixel className="ratio ratio-1x1"
                   backgroundColor="green"
                   animationName="greenPixel">
                <div className="flexbox-container text-black">
                    <div>Make</div>
                    <div>complex</div>
                    <div>things</div>
                    <div>simple!</div>
                </div>
            </Pixel>
            <div/>
            <Pixel className="ratio ratio-1x1"
                   backgroundColor="cyan"
                   animationName="cyanPixel">
                <div className="flexbox-container text-red">
                    <div>Inspire</div>
                    <div>goodness</div>
                    <div>&</div>
                    <div>positive</div>
                    <div>affirmation</div>
                </div>
            </Pixel>

            <div/>
            <div/>
            <Pixel className="ratio ratio-1x1"
                   backgroundColor="var(--kmeegaOrange)"
                   animationName="orangePixel">
                <div className="flexbox-container text-white">
                    <div>Automate<br/>Replicate<br/>Repeat</div>
                </div>
            </Pixel>
            <Pixel className="ratio ratio-1x1"
                   backgroundColor="yellow"
                   animationName="yellowPixel">
                <div className="flexbox-container text-magenta">
                    <div>Seek</div>
                    <div>opportunity</div>
                    <div>to make a</div>
                    <div>difference!</div>
                </div>
            </Pixel>
            <div/>

            <div/>
            <Pixel className="ratio ratio-1x1"
                   backgroundColor="red"
                   animationName="redPixel">
                <div className="flexbox-container text-black">
                    <div>Treasure</div>
                    <div>memories</div>
                    <div>and</div>
                    <div>stories!</div>
                </div>
            </Pixel>
            <div/>
            <div/>
            <div/>

            <div className="handItem ratio ratio-2x1">
                {/*<FaHandHolding size="20rem" color="yellow"/>*/}
                <svg stroke="#243777" fill="currentColor" strokeWidth="2"
                     viewBox="-10 300 600 225" color="yellow"
                     height="20em" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M565.3 328.1c-11.8-10.7-30.2-10-42.6 0L430.3 402c-11.3 9.1-25.4 14-40 14H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h78.3c15.9 0 30.7-10.9 33.3-26.6 3.3-20-12.1-37.4-31.6-37.4H192c-27 0-53.1 9.3-74.1 26.3L71.4 384H16c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h356.8c14.5 0 28.6-4.9 40-14L564 377c15.2-12.1 16.4-35.3 1.3-48.9z"></path>
                </svg>
            </div>
            <div/>
            <div className="contactUs fw-bold">
               Email info@kmeega.com for more information!
            </div>

        </StyledLogoGrid>

    )
}

export default LogoGrid;