import styled from "styled-components";

const StyledLogoGrid = styled.section`

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);
  max-width: 525px;
  padding: .5rem;
  margin: 3rem auto 0;
  font-size: calc(.47rem + .40vw);

  .handItem {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .contactUs {
    grid-column-start: 4;
    grid-column-end: 6;
    justify-self: end;
    align-self: center;
    color: var(--kmeegaOrange);
    font-size: calc(1rem + .22vw);
  }

  .fw-bold {
    font-weight: 700;
  }

  .ratio {
    position: relative;
    width: 100%;
  }

  .ratio::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: "";
  }

  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .ratio-1x1 {
    --aspect-ratio: 100%;
  }

  .ratio-2x1 {
    --aspect-ratio: calc(1 / 2 * 100%);
  }

  .ratio-3x1 {
    --aspect-ratio: calc(1 / 3 * 100%);
  }

  .ratio-4x3 {
    --aspect-ratio: calc(3 / 4 * 100%);
  }

  .ratio-16x9 {
    --aspect-ratio: calc(9 / 16 * 100%);
  }

  .ratio-21x9 {
    --aspect-ratio: calc(9 / 21 * 100%);
  }

  { /* Medium devices (landscape tablets, 768px and up)*/}
  @media only screen and (min-width: 768px) {
    font-size: calc(.80rem + .20vw);

  }

`

export default StyledLogoGrid;